import {inject } from 'aurelia-framework';
import {HttpClient, json} from 'aurelia-fetch-client';
import {Router,activationStrategy} from 'aurelia-router';
import { AppreciationService } from '../../shared/services/appreciationservice';
import {SharedState} from '../../shared/state/sharedstate';
import {config} from '../../shared/services/config';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { DialogService } from 'aurelia-dialog';
import { ShowDetailsComponent } from './showtopperformancedetails/showdetailscomponent';
import { ShowProfileComponent } from './showprofile/showprofilecomponent';
import { ShowPointsComponent } from './showpoints/showpointscomponent';
import $ from 'jquery'; 
import slick from 'slick-carousel';
import { ShowPopup } from '../alertpopup/showpopup';
import {LoginService} from "../../shared/services/loginservice";
import {JwtService} from "../../shared/services/jwtservice";


@inject(AppreciationService, SharedState, Router, HttpClient, Element, ValidationControllerFactory,DialogService,LoginService,JwtService)
export class Appreciation {
    selectedAppreciationType;
    showsearchEmpDropdown = false;
    pageSize = 5; //Appreciations Given
    pageSize1=5; //Appreciations  Received
    Feedback='';
    CategoryID='';
    selectedCategory='';
    AppreciationCategoryConfigID='';
    selectedCategoryId;
    CategoryIDArray=[];
    selectedEmployeeName = '';
    selectedsearchEmployee = [];
    selectedsearchEmployeeName = [];
    selectedRadio = '1';
    selectedEmployees = [];
    EmployeeListIDs = [];
    showEmpDropdown = false;
    notifyemails = [];
    dropdownOptions = [];
    isFormSubmitted = false;
    constructor(appreciationService, sharedState, router, httpClient, element, controllerFactory,dialogService,loginservice,JwtService) {
        this.appreciationService = appreciationService;
        this.sharedState = sharedState;
        this.router = router;
        this.httpClient = httpClient;
        this.element = element;
        this.dialogService = dialogService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        this.searchQuery = '';
        this.searchText = '';
        this.dropdownOptions = [];
        this.dropdownsearchOptions = [];
        this.selectedEmployees = [];
        this.selectedEmployee = null;
        this.filterEmpOption = '';
        this.ShowDetails = [];
        this.selectedEmployees = [];
        this.selectedCategory = null;
        this.loginservice = loginservice;
        this.jwtService = JwtService;
        ValidationRules
            .ensure('Feedback').required().withMessage('Feedback field is required.')
            .ensure('searchText')
            .required()
            .withMessage('Employee name is required')
            .ensure('selectedCategory')
            .required()
            .withMessage('Please select atleast one appreciation category.')
            .on(this);
    }
    validateEmails() {
        if (this.emails && this.emails.trim() !== '') {
            const emails = this.emails.split(',');
            const emailRegex = /^[^\s@]+@[^\s@]+\.(com|co|in|org|cc|net)$/;
            const invalidEmails = [];
            for (const email of emails) {
                const trimmedEmail = email.trim();
                if (!emailRegex.test(trimmedEmail)) {
                    invalidEmails.push(trimmedEmail);
                }
            }
            if (invalidEmails.length > 0 || (emails.length > 1 && this.emails.indexOf(',') === -1)) {
                this.specifyemailerror = 'Invalid email address and Email(s) should be separated by commas';
                return false;
            }
        }
        this.specifyemailerror = '';
        return true;
    }
      getAppreciations=[]; //Appreciation type
      MyAppreciationSummay=[];
      AppreciationsProvidedList=[]; //Total Appreciations Given
      AppreciationDetails=[]; // Total Appreciations Received
      TopPerformancePreviewDetails=[];
      previousYear = this.getPreviousYear();
      configimgurl=config.img_url;
      Configurl=config.api_img_url;

      attached(){
          // if(this.jwtService.getToken() === undefined){
          //     this.loginservice.purgeAuth();
          //     this.router.navigateToRoute('login')
          // }

        // Get Total Appreciations Given
            this.LoginempId=window.localStorage['EmployeeID'];
                  
            this.appreciationService.GetAppreciationsProvidedList(this.LoginempId)
            .then(AppreciationsProvidedList =>{
              this.AppreciationsProvidedList = AppreciationsProvidedList;         
            });

       // Get Total Appreciations Received
            this.appreciationService.getMyAppreciationDetails(this.LoginempId,this.previousYear)
            .then(AppreciationDetails =>{
              this.AppreciationDetails = AppreciationDetails;  
              this.selectedCategoryDetails=this.AppreciationDetails;
              });
            this.appreciationService.getMyAppreciationSummary(this.LoginempId,this.previousYear)
            .then(MyAppreciationSummay =>{
              this.MyAppreciationSummay = MyAppreciationSummay.EmployeeAppreciationModelList;  
              });
           //Top performance preview
              this.appreciationService.GetTopPerformanceList(this.previousYear)
              .then(reviewDetails =>{
                this.TopPerformancePreviewDetails = reviewDetails.EmployeeAppreciationModelList;  
            });
             //Appreciation types

              this.appreciationService.GetAppreciations()
              .then(getAppreciations => {
              this.getAppreciations=getAppreciations;
                // console.log(getAppreciations);
              })
                setTimeout(()=>{
              const tabTriggerBtns = document.querySelectorAll('.appreciation-type ul li');
               tabTriggerBtns.forEach(function(tabTriggerBtn, index){
                 tabTriggerBtn.addEventListener('click', function(){
                     const currentTabData = document.querySelector('.appreciation-tab-content[data-tab-content="' + this.dataset.tabTrigger + '"]');
                     document.querySelector('.appreciation-tab-content.is-active').classList.remove('is-active');
                     document.querySelector('.appreciation-type ul li.active').classList.remove('active');
                     currentTabData.classList.add('is-active');
                     this.classList.add('active');
                 });
             });

               $('.performance-slider').slick({
                  dots: false, 
                  infinite: false, 
                  arrows: true, 
                  slidesToShow: 5,
                  slidesToScroll: 1,
                  responsive: [
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 3,
                    }
                  },
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 4,
                    }
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 3,
                    }
                  },
                  {
                    breakpoint: 666,
                    settings: {
                      slidesToShow: 1,
                    }
                  }
                ]
              })
            },5000)
        }
        onToggleClick() {
          event.preventDefault();
          const parentTr = event.target.closest('tr');
          if (parentTr) {
            parentTr.classList.toggle('open-comment');
          }
        }
        getPreviousYear() {
          const myDate = new Date();
          myDate.setFullYear(myDate.getFullYear()-1 ); // -1
          return myDate.getFullYear();
        }
    async onsearchEmployeeSelected() {
      this.selectedCategoryErrors=[];
        const trimmedSearchText = this.searchText.trim(); // Remove leading and trailing spaces
        this.searchedtext = this.searchText;
        if (trimmedSearchText.length > 0) {
            // Non-empty input, proceed to open the dropdown and perform filtering
            this.showsearchEmpDropdown = true;
            this.noEmployeeFoundMessage = null;
            try {
                const response = await this.appreciationService.GetEmployeeNames(this.searchedtext);
                const data = await response;
                const filterOption = trimmedSearchText.toLowerCase();
                this.dropdownsearchOptions = data.filter(options => options.EmployeeName.toLowerCase().includes(filterOption));

                // Assuming this.LoginempId is a string, convert it to a number
                const loginEmpId = parseInt(this.LoginempId, 10);

                this.dropdownsearchOptions = this.dropdownsearchOptions.filter(option => option.EmployeeID !== loginEmpId);

                if (this.dropdownsearchOptions.length === 0) {
                    this.noEmployeeFoundMessage = "No records found";
                }else {
                    this.noEmployeeFoundMessage = "";
                }
            } catch (error) {
                //console.error(error);
            }
        } else {
            this.showsearchEmpDropdown = false;
            this.dropdownsearchOptions = [];
            this.noEmployeeFoundMessage = null;
            
        }
    }
    selectsearchEmployee(EmpOption) {
        const modifiedOption = { ...EmpOption, ID: EmpOption.EmployeeID };
        this.searchText = modifiedOption.EmployeeName;
        this.showsearchEmpDropdown = false;
        this.selectedsearchEmployee = modifiedOption;
        this.selectedsearchEmployeeName=this.searchText;

    }
    async  toggleNotifyFields(checked) {
        this.showNotifyFields = checked;
        this. selectedRadio = '1';
    }
    onEmailSelected() {
        this.selectedRadio = '2';
        this.searchQuery = '';
        this.employenameerror = '';
        this.employenameerror = '';
    }
    async onEmployeeSelected() {
        this.selectedRadio = '1';
        this.emails = '';
        this.specifyemailerror = '';
        if (this.searchQuery) {
            this.showEmpDropdown = true;

            try {
                const response = await this.appreciationService.GetEmployeeNames(this.searchQuery);
                const data = await response;
                const filterOption = this.searchQuery.toLowerCase();
                this.dropdownOptions = data.filter(option => option.EmployeeName.toLowerCase().includes(filterOption));
                this.empid = this.jwtService.getEmployeeId(); // Assuming this.empid is a string
                this.dropdownOptions = this.dropdownOptions.filter(option => option.EmployeeID !== parseInt(this.empid, 10));
                if (this.dropdownOptions.length === 0) {
                    const errorInfo = {
                        error: {
                            message: "No records found"
                        }
                    };
                    this.NameError = [errorInfo];
                } else {
                    this.NameError = [];
                }
            } catch (error) {
            }
        } else {
            this.showEmpDropdown = false;
            this.dropdownOptions = [];
            
            
        }
    }
    selectEmployee(EmpOption) {
        const modifiedOption = {
            ...EmpOption,
            ID: EmpOption.EmployeeID
        };
        this.searchQuery = modifiedOption.EmployeeName;
        this.showEmpDropdown = false;
        this.selectedEmployee = modifiedOption;
        return false;
    }
    addToSelectedOptions(id) {
       // this.NameError=null;
      //  if (this.selectedEmployee===null) {
      //   this.dialogService.open({viewModel: ShowPopup, model:""});
      //   //alert("Please select valid employee.");
      //   }
        let emails = this.emails || '';
        if (this.selectedRadio === '1') {
            if (!this.searchQuery || this.searchQuery.trim() === '') {
                this.employenameerror = "Employee name is required";
                return;
            }
           else if (this.selectedEmployee===null) {
            this.employenameerror=null;
              this.dialogService.open({viewModel: ShowPopup, model:""});
              //alert("Please select valid employee.");
              }
            this.appreciationService.GetEmployeeNamesForGrid(id).then(data => {
                this.Ids = data.EmployeeID;
                this.empmail = data.EmailID;
                this.EName = data.EmployeeName;
                let employeeemail = {
                    EmailIds: this.empmail,
                    PersonID: this.Ids,
                    EmployeeName: this.EName,
                };
                const existingEmployee = this.selectedEmployees.find(emp => emp.PersonID === id);
                if (existingEmployee) {
                    this.employenameerror = "Employee name already exists";
                    return;
                }
                this.EmployeeListIDs.push(employeeemail);
                this.selectedEmployees.push(employeeemail);
                this.searchQuery = '';
                this.employenameerror = '';
            });
        } else if (this.selectedRadio === '2') {
            if (this.validateEmails()) {
                // Check if the email already exists
                const existingEmail = this.selectedEmployees.find(emp => emp.EmailIds === emails);
                if (existingEmail) {
                    this.specifyemailerror = "Email id already exists";
                    return;
                }
                if (emails.trim() !== '') {
                    const emailEntry = {
                        PersonID: -1,
                        EmailIds: emails
                    };
                    this.newmail = emailEntry;
                    this.notifyemails.push(this.newmail);
                    this.EmployeeListIDs.push(...this.notifyemails);
                    this.selectedEmployees.push(emailEntry);
                    this.emails = '';
                } else {
                    this.specifyemailerror = "Specify email is required";
                    return;
                }
                this.employenameerror = '';
                this.specifyemailerror = '';
                this.validatemessage = '';
            }
        }
    }

    deleteEmployee(emp) {
        const index = this.selectedEmployees.indexOf(emp);
        if (index !== -1) {
            this.selectedEmployees.splice(index, 1);
            this.EmployeeListIDs.splice(index, 1);
        }
        const deletedIds = emp.EmployeeID;
        this.EmployeeListIDs = this.EmployeeListIDs.filter(id => id !== deletedIds);
        this.Ids = this.EmployeeListIDs;
    }

            Submit() {
                this.controllerFactory.validate()
                    .then(result => {
                        if (result.valid) {
                          console.log(this.catid)
                           if(this.catid === undefined || this.catid === null)
                           {
                            this.catagoryerror = 'Please select appreciation type';
                               const errorElement = document.querySelector(".error-messages");
                               if (errorElement) {
                                   errorElement.scrollIntoView();
                               }
                            }else{

                           if( this.notifyind === true && this.EmployeeListIDs.length === 0 && this.notifyemails.length === 0) {
                              this.validatemessage = "Atleast one peer is required";
                               const errorElement = document.querySelector(".error-messages");
                               if (errorElement) {
                                   errorElement.scrollIntoView();
                               }
                          }else {
                              this.validatemessage = "";
                              let data = {};
                              data.ReceivedEmployeeID = this.selectedsearchEmployee.EmployeeID;
                              data.Feedback = this.Feedback;
                              data.ProvidedEmployeeID = this.LoginempId;
                              data.CategoryID = this.catid;
                              data.NotifyEmployeeList = this.EmployeeListIDs;
                              data.NotifyPeersIND = this.notifyind;
                              data.MessageToPeers = this.comments;
                               this.isFormSubmitted = true;
                              this.appreciationService.SaveAppreciation(data)
                                  .then(() => {
                                      this.successMessage = 'Appreciation submitted successfully';
                                      setTimeout(() => {
                                          this.successMessage = null;
                                      }, 5000);
                                      const errorElement = document.querySelector(".success-message");
                                      if (errorElement) {
                                          errorElement.scrollIntoView();
                                      }
                                      this.selectedEmployee = null;
                                      this.searchQuery = null;
                                      this.searchText = null;
                                      this.CategoryID = null;
                                      this.Feedback = '';
                                      this.errorMessage = '';
                                      this.selectedCategory = null;
                                      this.EmployeeListIDs = [];
                                      this.notifyind = '';
                                      this.selectedEmployees = [];
                                      this.notifyemails = [];
                                      this.searchQuery = '';
                                      this.employenameerror = '';
                                      this.showNotifyFields = false;
                                      this.comments = '';
                                      this.catagoryerror = '';
                                      this.validatemessage = '';
                                      this.selectedCategory = '';
                                      this.appreciationtype='';
                                      this.catid =null;
                                      this.isFormSubmitted = false;

                                      const liElements = document.querySelectorAll('.appreciation-type li');
                                      const activeLi = document.querySelector('.appreciation-type li.active');
                                      if (activeLi) {
                                        activeLi.classList.remove('active');
                                        liElements[0].classList.remove('active'); //add instaed of remove after click on reset active move to first
                                      }
                      
                                      const tabContentElements = document.querySelectorAll('.appreciation-tab-content');
                                      const activeTabContent = document.querySelector('.appreciation-tab-content.is-active');
                                      if (activeTabContent) {
                                        activeTabContent.classList.remove('is-active');
                                        tabContentElements[0].classList.remove('is-active'); //add instaed of remove after click on reset active move to first
                                      }

                                      this.LoginempId = window.localStorage['EmployeeID'];

                                      this.appreciationService.GetAppreciationsProvidedList(this.LoginempId)
                                          .then(AppreciationsProvidedList => {
                                              this.AppreciationsProvidedList = AppreciationsProvidedList;
                                          });

                                  })
                                  .catch((error) => {
                                      this.successMessage = '';
                                      this.errorMessage = 'Error submitting appreciation: ' + error;
                                      const errorElement = document.querySelector(".error-messages");
                                      if (errorElement) {
                                          errorElement.scrollIntoView();
                                      }
                                  });
                          }
                           }
                        }else {
                            const errorElement = document.querySelector(".error-messages");
                            if (errorElement) {
                                errorElement.classList.add("scroll-to-error");
                                errorElement.scrollIntoView({ behavior: "auto" });
                            }
                        }
                    });
                    

            }

            ResetForm(){
                this.selectedEmployee = null;
                this.searchQuery = null;
                this.searchText = null;
                this.CategoryID = null;
                this.Feedback = null;
                this.errorMessage = '';
                this.selectedCategory = null;
                this.EmployeeListIDs = [];
                this.notifyind = '';
                this.selectedEmployees = [];
                this.notifyemails = [];
                this.searchQuery = '';
                this.employenameerror = '';
                this.showNotifyFields = false;
                this.comments = '';
                //this.NameError='';
                this.employenameerror = '';
                this.specifyemailerror='';
                this.searchTextError = [];
                this.FeedbackErrors = [];
                this.NameError=null;
                this.showEmpDropdown=null;
                this.noEmployeeFoundMessage='';
                this.catagoryerror = '';
                this.validatemessage = '';
                this.catid =null;

                const liElements = document.querySelectorAll('.appreciation-type li');
                const activeLi = document.querySelector('.appreciation-type li.active');
                if (activeLi) {
                  activeLi.classList.remove('active');
                  liElements[0].classList.remove('active'); //add instaed of remove after click on reset active move to first
                }

                const tabContentElements = document.querySelectorAll('.appreciation-tab-content');
                const activeTabContent = document.querySelector('.appreciation-tab-content.is-active');
                if (activeTabContent) {
                  activeTabContent.classList.remove('is-active');
                  tabContentElements[0].classList.remove('is-active'); //add instaed of remove after click on reset active move to first
                }

            }

              handleClick(appreciationtype) {

                  const selectedCategoryId = appreciationtype.AppreciationCategoryConfigID;
                  const listItems = document.querySelectorAll('.appreciation-type li');
                  listItems.forEach((item) => {
                  item.classList.remove('active');
                 this.catagoryerror='';
                  
              });
              
                  const selectedListItem = document.querySelector(`[data-tab-trigger="${appreciationtype.AppreciationCategoryConfigID}"]`);
                  selectedListItem.classList.add('active');
                  const appreciationContents = document.querySelectorAll('.appreciation-tab-content');
                  appreciationContents.forEach((content) => {
                  content.classList.remove('is-active');
              });
              
                  const selectedContent = document.querySelector(`[data-tab-content="${appreciationtype.AppreciationCategoryConfigID}"]`);
                  selectedContent.classList.add('is-active');
              
                  this.Tabdata={
                    categoryId:selectedCategoryId,
                    
                  };
                  this.catid = this.Tabdata.categoryId;
                 
            }
            
              showPopupGrid(performance) {
                
                this.dialogService.open({viewModel: ShowDetailsComponent, model: performance});
              }

              showProfile(performance){
                
                this.dialogService.open({viewModel: ShowProfileComponent, model: performance})
              }

              showScore(performance)
              {
                this.dialogService.open({viewModel: ShowPointsComponent, model: performance})
              }
               
             selectedCategoryDetails = [];

              show(option)
              {
                if(option != '')
                {
                  this.selectedCategoryDetails = this.AppreciationDetails.filter((getappreciation) => getappreciation.CategoryName === option.CategoryName);
                }
                else{
                  this.selectedCategoryDetails=this.AppreciationDetails;
                }
                
              }
            
  }
