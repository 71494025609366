import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { AppreciationService } from '../../../shared/services/appreciationservice';




@inject(DialogController,AppreciationService)
export class ShowDetailsComponent {
    
   

      constructor(controller,appreciationService) {
          this.controller = controller;
          this.appreciationService = appreciationService;
              
      }
      
      CategoryList=[];
      pageSize=10;
      previousYear = this.getPreviousYear();
    
    

      async activate(performance)
      {
         this.appreciationcategory= performance.CategoryName;
          // console.log(performance.CategoryID);
          this.appreciationService.GetTopPerformanceListByCategory(this.previousYear,performance.CategoryID)
          .then(CategoryList =>{
            this.CategoryList = CategoryList;  
          // console.log(this.CategoryList);
          
        });   

      }

      getPreviousYear() {
        const myDate = new Date();
        myDate.setFullYear(myDate.getFullYear()); //-1
        return myDate.getFullYear();
      }
   
      cancel() {
          this.controller.cancel();
      }

      filters = [
        {value: '', keys: ['EmployeeName','DepartmentName','Score']},
    ];
    
}