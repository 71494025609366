import {inject } from 'aurelia-framework';
import {HttpClient, json} from 'aurelia-fetch-client';
import {Router,activationStrategy} from 'aurelia-router';
import { AppreciationService } from '../../shared/services/appreciationservice';
import {SharedState} from '../../shared/state/sharedstate';
import {config} from '../../shared/services/config';
import {LoginService} from "../../shared/services/loginservice";
import {JwtService} from "../../shared/services/jwtservice";

@inject(AppreciationService, SharedState, Router, HttpClient,LoginService,JwtService, Element)
export class MyAppreciation{

    constructor(appreciationService, sharedState, router, httpClient,loginservice,JwtService, element) {
        this.appreciationService = appreciationService;
        this.sharedState = sharedState;
        this.router = router;
        this.httpClient = httpClient;
        this.element = element;
        this.loginservice = loginservice;
        this.jwtService = JwtService;
      }
      
      previousYear = this.getPreviousYear();
      AppreciationYearList=[];
      selectedYear=this.previousYear;
      LoginempId='';
      AppreciationDetails=[];
      
      configurl=config.img_url;

      attached(){
          // if(this.jwtService.getToken() === undefined){
          //     this.loginservice.purgeAuth();
          //     this.router.navigateToRoute('login')
          // }
          this.LoginempId=window.localStorage['EmployeeID'];
          this.appreciationService.GetAppreciationYearList(this.LoginempId)
              .then(AppreciationYearList =>{
                this.AppreciationYearList = AppreciationYearList;           
              });
          this.appreciationService.getMyAppreciationDetails(this.LoginempId, this.previousYear)
              .then(AppreciationDetails =>{
                this.AppreciationDetails = AppreciationDetails;  
              });
     }

       getPreviousYear() {
        const myDate = new Date();
        myDate.setFullYear(myDate.getFullYear() - 1);
        return myDate.getFullYear();
      }

      Search(){
        
        this.appreciationService.getMyAppreciationDetails(this.LoginempId, this.selectedYear)
              .then(AppreciationDetails =>{
                this.AppreciationDetails = AppreciationDetails;  
                      
               // console.log(this.AppreciationDetails);
              
                });

      }

      Reset(){

        this.selectedYear="";
        window.location.reload(this.selectedYear);
      }


    
}