import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { AppreciationService } from '../../../shared/services/appreciationservice';
import { ProfileService } from '../../../shared/services/profielservice';
import {JwtService} from '../../../shared/services/jwtservice';
import {config} from '../../../shared/services/config';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(DialogController,AppreciationService,ProfileService,JwtService,LoginService,Router)
export class ShowProfileComponent {
    configimgurl=config.img_url;
    Configurl=config.api_img_url;
    isDragging = false;
    dialog;
    static inject = [Element];
        constructor(controller,appreciationService,ProfileService,JwtService,loginservice,router) {
            this.controller = controller;
            this.appreciationService = appreciationService;
            this.profileService=ProfileService;
            this.jwtService = JwtService;
            this.router = router;
            this.loginservice = loginservice;
            this.empid = this.jwtService.getEmployeeId();
        }
        EmployeeCode='';
        async  activate(data)
            {
                const generalInformationData = await this.profileService.EmployeeDetails(data.EmployeeID)
                this.generalInformation = generalInformationData;
            
                // this.empdata={
                //     Employeecode:generalInformationData.EmployeeCode,
                //     RM:generalInformationData.ReportingManagerName,
                // };
                // console.log(this.generalInformation);

                this.employeeDetails = data;
                this.EmployeeId = data.EmployeeID;
                this.Photopath=data.PhotoPath;
                this.EmployeeName=data.EmployeeName;
                this.Designation=this.generalInformation.Designation;
                this.DepartmentName=data.DepartmentName;
                this.ReportingManagerName=this.generalInformation.ReportingManagerName;
                this.EmployeeCode=this.generalInformation.EmployeeCode;
                this.EmployeeClassText=this.generalInformation.EmployeeClassText;

                // console.log(this.EmployeeCode);
                // console.log(data.PhotoPath);
            }
    attached(){
        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
    }
    
            cancel() {
                this.controller.cancel();
            }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    };

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
            
}

