import {inject } from 'aurelia-framework';
import {HttpClient, json} from 'aurelia-fetch-client';
import {Router,activationStrategy} from 'aurelia-router';
import { AppreciationService } from '../../shared/services/appreciationservice';
import {SharedState} from '../../shared/state/sharedstate';
import {config} from '../../shared/services/config';
import {LoginService} from "../../shared/services/loginservice";
import {JwtService} from "../../shared/services/jwtservice";

@inject(AppreciationService, SharedState, Router, HttpClient,LoginService,JwtService, Element)
export class AppreciationsProvidedByMe{

    constructor(appreciationService, sharedState, router, httpClient,JwtService,loginservice, element) {
        this.appreciationService = appreciationService;
        this.sharedState = sharedState;
        this.router = router;
        this.httpClient = httpClient;
        this.element = element;
        this.loginservice = loginservice;
        this.jwtService = JwtService;
      }
      
      AppreciationsProvidedList=[];
      pageNumber = 1;
      itemsPerPage = 5;
      totalItems=0;
      displayedItems = [];
      startIndex;
      endIndex;
    
      attached() {
        this.updateDisplayedItems();
          // if(this.jwtService.getToken() === undefined){
          //     this.loginservice.purgeAuth();
          //     this.router.navigateToRoute('login')
          // }
          this.LoginempId=window.localStorage['EmployeeID'];
          this.appreciationService.GetAppreciationsProvidedList(this.LoginempId)
              .then(AppreciationsProvidedList =>{
                  this.AppreciationsProvidedList = AppreciationsProvidedList;
                  this.totalItems = AppreciationsProvidedList.length;


              });
      }
    
      getTotalPages() {
        return Math.ceil(this.totalItems / this.itemsPerPage);
        
      }
    
      getPageRange(pageNumber, itemsPerPage, totalItems) {
        const startIndex = (pageNumber - 1) * itemsPerPage + 1;
        const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
        return { startIndex, endIndex };
      }
    
      goToFirstPage() {
        if (this.pageNumber > 1) {
          this.pageNumber = 1;
          this.updateDisplayedItems();
        }
      }
    
      goToPreviousPage() {
        if (this.pageNumber > 1) {
          this.pageNumber--;
          this.updateDisplayedItems();
        }
      }
    
      goToNextPage() {
        if (this.pageNumber < this.getTotalPages()) {
          this.pageNumber++;
          this.updateDisplayedItems();
        }
      }
    
      goToLastPage() {
        if (this.pageNumber < this.getTotalPages()) {
          this.pageNumber = this.getTotalPages();
          this.updateDisplayedItems();
        }
      }
    
      updateDisplayedItems() {
        const { startIndex, endIndex } = this.getPageRange(
          this.pageNumber,
          this.itemsPerPage,
          this.totalItems
        );
        this.startIndex = startIndex;
        this.endIndex = endIndex;
        this.displayedItems = this.getItemsForCurrentPage();
      }
    
      getItemsForCurrentPage() {
        const start = (this.pageNumber - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.AppreciationsProvidedList.slice(start, end);
      }

}