import {inject } from 'aurelia-framework';
import {HttpClient, json} from 'aurelia-fetch-client';
import {Router,activationStrategy} from 'aurelia-router';
import { AppreciationService } from '../../shared/services/appreciationservice';
import {SharedState} from '../../shared/state/sharedstate';
import {config} from '../../shared/services/config';
import {LoginService} from "../../shared/services/loginservice";
import {JwtService} from "../../shared/services/jwtservice";

@inject(AppreciationService, SharedState, Router, HttpClient,LoginService,JwtService, Element)
export class TopPerformancePreview{

    constructor(appreciationService, sharedState, router, httpClient,loginservice,JwtService, element) {
        this.appreciationService = appreciationService;
        this.sharedState = sharedState;
        this.router = router;
        this.httpClient = httpClient;
        this.element = element;
        this.loginservice = loginservice;
        this.jwtService = JwtService;
      }
      
      previousYear = this.getPreviousYear();
      TopPerformanceYearList=[];
      selectedYear=this.previousYear;
      LoginempId='';
      TopPerformancePreviewDetails=[];
      configurl=config.img_url;

      attached(){
          // if(this.jwtService.getToken() === undefined){
          //     this.loginservice.purgeAuth();
          //     this.router.navigateToRoute('login')
          // }
          this.LoginempId=window.localStorage['EmployeeID'];
          this.appreciationService.GetAppreciationYearList(this.LoginempId)
        .then(TopPerformanceYearList =>{
          this.TopPerformanceYearList = TopPerformanceYearList;           
        });
          this.appreciationService.GetTopPerformanceList(this.previousYear)
              .then(TopPerformancePreviewDetails =>{
                this.TopPerformancePreviewDetails = TopPerformancePreviewDetails;  
              });
      }
      getPreviousYear() {
        const myDate = new Date();
        myDate.setFullYear(myDate.getFullYear() - 1);
        return myDate.getFullYear();
      }
      Search()
      {
        this.appreciationService.GetTopPerformanceList(this.selectedYear)
        .then(TopPerformancePreviewDetails =>{
          this.TopPerformancePreviewDetails = TopPerformancePreviewDetails;
        });
      }
      Reset()
      {
          this.selectedYear="";
          window.location.reload();
      }
    
}