import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { AppreciationService } from '../../../shared/services/appreciationservice';




@inject(DialogController,AppreciationService)
export class ShowPointsComponent {
    
    
            constructor(controller,appreciationService) {
                this.controller = controller;
                this.appreciationService = appreciationService;

            }
             
            AppreciationsList=[];
            pageSize=5;
            previousYear = this.getPreviousYear();
    
    

            async activate(performance)
            {
                this.EmployeeName=performance.EmployeeName;
                this.Category=performance.CategoryName;
                this.Department=performance.DepartmentName;
                //console.log(performance.EmployeeID);
                this.appreciationService.GetEmployeeAppreciationsByID(this.previousYear,performance.CategoryID,performance.EmployeeID)
                .then(AppreciationsList =>{
                    this.AppreciationsList = AppreciationsList;  
                    //console.log(this.AppreciationsList);
                
                });   

            }

                getPreviousYear() {
                const myDate = new Date();
                myDate.setFullYear(myDate.getFullYear()); //-1
                return myDate.getFullYear();
                }
   
                cancel() {
                    this.controller.cancel();
                }

                
      filters = [
        {value: '', keys: ['ProvidedEmployeeName','AppreciationDateText','Score']},
    ];
    
}